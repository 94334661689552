/* global config, Promise, angular, moment */

'use strict';
angular.module('managerApp').factory('procedureTools', ($timeout, $mdDialog, toast, documents, procedureService, $location, $route, $rootScope, $window) => {

    const CREATION_COMPTE_PROFESSIONNEL_PARTENAIRE = 'creation-compte-professionnel';
    const CERTIFICAT_IMMATRICULATION = 'certificat-immatriculation';
    const CERTIFICAT_IMMATRICULATION_WW = 'certificat-immatriculation-ww';
    const DUPLICATA = 'duplicata';
    const VENTE_EN_LIGNE = 'vente-en-ligne';
    const DECLARATION_ACHAT = 'declaration-achat';
    const DECLARATION_CESSION = 'declaration-cession';
    const CERTIFICAT_SITUATION_ADMINISTRATIVE = 'certificat-situation-administrative';
    const PLAQUES_IMMATRICULATION = 'plaques-immatriculation';
    const CHANGEMENT_ADRESSE = 'changement-adresse';
    const FICHE_IDENTIFICATION_VEHICULE = 'fiche-identification-vehicule';
    const CERTIFICAT_IMMATRICULATION_VN = 'certificat-immatriculation-vn';
    const CERTIFICAT_IMMATRICULATION_VI = 'certificat-immatriculation-vi';

    const DEFAULT_ERROR_MESSAGE = 'Une erreur est survenue lors du traitement de votre demande.';
    const TEMPLATES_DIR = config.app.templatesDir;

    const PERSONNE_PHYSIQUE = 'personne-physique';
    const PERSONNE_MORALE = 'personne-morale';

    let documentTypes = {
        'document': {
            name: 'document',
            label: 'Document',
            type: true
        },
        'photo': {
            name: 'photo',
            label: 'Photo',
            type: true
        },
        'certificat-immatriculation': {
            name: 'certificat-immatriculation',
            label: 'Certificat d\'immatriculation',
            type: true
        },
        'recto-carte-grise': {
            name: 'recto-carte-grise',
            label: 'Certificat d\'immatriculation recto',
            type: true
        },
        'verso-carte-grise': {
            name: 'verso-carte-grise',
            label: 'Certificat d\'immatriculation verso',
            type: true
        },
        'certificat-cession': {
            name: 'certificat-cession',
            label: 'Certificat de cession',
            type: true
        },
        'controle-technique': {
            name: 'controle-technique',
            label: 'Procès-verbal de contrôle technique',
            type: true
        },
        'certificat-situation-administrative': {
            name: 'certificat-situation-administrative',
            label: 'Certificat de situation administrative',
            type: true
        },
        'declaration-achat': {
            name: 'declaration-achat',
            label: 'Le récépissé de déclaration d\'achat',
            type: true
        },
        'kbis': {
            name: 'kbis',
            label: 'Extrait Kbis / Avis de situation Sirene (entrepreneur individuel)',
            type: true
        },
        'piece-identite': {
            name: 'piece-identite',
            label: 'Pièce d\'identité',
            type: true
        },
        'rib': {
            name: 'rib',
            label: 'Relevé d\'identité bancaire (RIB)',
            type: true
        },
        'permis-conduire': {
            name: 'permis-conduire',
            label: 'Permis de conduire',
            type: true
        },
        'piece-identite-titulaire': {
            name: 'piece-identite-titulaire',
            label: 'Pièce d\'identité du titulaire actuel (le vendeur)',
            type: true
        },
        'piece-identite-acquereur': {
            name: 'piece-identite-acquereur',
            label: 'Pièce d\'identité de l\'acquéreur',
            type: true
        },
        'piece-identite-co-acquereur': {
            name: 'piece-identite-co-acquereur',
            label: 'Pièce d\'identité du co acquéreur',
            type: true
        },
        'piece-identite-representant': {
            name: 'piece-identite-representant',
            label: 'Pièce d\'identité du représentant légal',
            type: true
        },
        'piece-identite-cotitulaire': {
            name: 'piece-identite-cotitulaire',
            label: 'Pièce d\'identité du cotitulaire',
            type: true
        },
        'piece-identite-hebergeur': {
            name: 'piece-identite-hebergeur',
            label: 'Pièce d\'identité de l\'hébergeur',
            type: true
        },
        'piece-identite-heritier-01': {
            name: 'piece-identite-heritier-01',
            label: "Pièce d'identité d'héritier",
            type: true
        },
        'piece-identite-heritier-02': {
            name: 'piece-identite-heritier-02',
            label: "Pièce d'identité d'héritier",
            type: true
        },
        'piece-identite-heritier-03': {
            name: 'piece-identite-heritier-03',
            label: "Pièce d'identité d'héritier",
            type: true
        },
        'piece-identite-heritier-04': {
            name: 'piece-identite-heritier-04',
            label: "Pièce d'identité d'héritier",
            type: true
        },
        'piece-identite-heritier-05': {
            name: 'piece-identite-heritier-05',
            label: "Pièce d'identité d'héritier",
            type: true
        },
        'piece-identite-heritier-06': {
            name: 'piece-identite-heritier-06',
            label: "Pièce d'identité d'héritier",
            type: true
        },
        'piece-identite-heritier-07': {
            name: 'piece-identite-heritier-07',
            label: "Pièce d'identité d'héritier",
            type: true
        },
        'piece-identite-heritier-08': {
            name: 'piece-identite-heritier-08',
            label: "Pièce d'identité d'héritier",
            type: true
        },
        'piece-identite-heritier-09': {
            name: 'piece-identite-heritier-09',
            label: "Pièce d'identité d'héritier",
            type: true
        },
        'piece-identite-heritier-10': {
            name: 'piece-identite-heritier-10',
            label: "Pièce d'identité d'héritier",
            type: true
        },
        'attestation-assurance': {
            name: 'attestation-assurance',
            label: 'Attestation d\'assurance',
            type: true
        },
        'justificatif-domicile': {
            name: 'justificatif-domicile',
            label: 'Justificatif de domicile',
            type: true
        },
        'justificatif-domicile-hebergeur': {
            name: 'justificatif-domicile-hebergeur',
            label: 'Justificatif de domicile de l\'hébergeur',
            type: true
        },
        'autorisation-parentale': {
            name: 'autorisation-parentale',
            label: 'Autorisation parentale',
            type: true
        },
        'carte-identite-recto': {
            name: 'carte-identite-recto',
            label: 'Carte d\'identité recto',
            type: [
                'piece-identite', 'piece-identite-acquereur',
                'piece-identite-co-acquereur', 'piece-identite-representant',
                'piece-identite-cotitulaire', 'piece-identite-titulaire',
                'piece-identite-hebergeur', 'piece-identite-heritier-01',
                'piece-identite-heritier-02', 'piece-identite-heritier-03',
                'piece-identite-heritier-04', 'piece-identite-heritier-05',
                'piece-identite-heritier-06', 'piece-identite-heritier-07',
                'piece-identite-heritier-08', 'piece-identite-heritier-09',
                'piece-identite-heritier-10', 'piece-identite-mandataire'
            ]
        },
        'carte-identite-verso': {
            name: 'carte-identite-verso',
            label: 'Carte d\'identité verso',
            type: [
                'piece-identite', 'piece-identite-acquereur',
                'piece-identite-co-acquereur', 'piece-identite-representant',
                'piece-identite-cotitulaire', 'piece-identite-titulaire',
                'piece-identite-hebergeur', 'piece-identite-heritier-01',
                'piece-identite-heritier-02', 'piece-identite-heritier-03',
                'piece-identite-heritier-04', 'piece-identite-heritier-05',
                'piece-identite-heritier-06', 'piece-identite-heritier-07',
                'piece-identite-heritier-08', 'piece-identite-heritier-09',
                'piece-identite-heritier-10', 'piece-identite-mandataire'
            ]
        },
        'passport': {
            name: 'passport',
            label: 'Passeport',
            type: [
                'piece-identite', 'piece-identite-acquereur',
                'piece-identite-co-acquereur', 'piece-identite-representant',
                'piece-identite-cotitulaire', 'piece-identite-titulaire',
                'piece-identite-hebergeur', 'piece-identite-heritier-01',
                'piece-identite-heritier-02', 'piece-identite-heritier-03',
                'piece-identite-heritier-04', 'piece-identite-heritier-05',
                'piece-identite-heritier-06', 'piece-identite-heritier-07',
                'piece-identite-heritier-08', 'piece-identite-heritier-09',
                'piece-identite-heritier-10', 'piece-identite-mandataire'
            ]
        },
        'permis-conduire-recto': {
            name: 'permis-conduire-recto',
            label: 'Permis de conduire recto',
            type: [
                'permis-conduire', 'piece-identite', 'piece-identite-acquereur',
                'piece-identite-co-acquereur', 'piece-identite-representant',
                'piece-identite-cotitulaire', 'piece-identite-titulaire',
                'piece-identite-hebergeur', 'piece-identite-heritier-01',
                'piece-identite-heritier-02', 'piece-identite-heritier-03',
                'piece-identite-heritier-04', 'piece-identite-heritier-05',
                'piece-identite-heritier-06', 'piece-identite-heritier-07',
                'piece-identite-heritier-08', 'piece-identite-heritier-09',
                'piece-identite-heritier-10', 'piece-identite-mandataire'
            ]
        },
        'permis-conduire-verso': {
            name: 'permis-conduire-verso',
            label: 'Permis de conduire verso',
            type: [
                'permis-conduire', 'piece-identite', 'piece-identite-acquereur',
                'piece-identite-co-acquereur', 'piece-identite-representant',
                'piece-identite-cotitulaire', 'piece-identite-titulaire',
                'piece-identite-hebergeur', 'piece-identite-heritier-01',
                'piece-identite-heritier-02', 'piece-identite-heritier-03',
                'piece-identite-heritier-04', 'piece-identite-heritier-05',
                'piece-identite-heritier-06', 'piece-identite-heritier-07',
                'piece-identite-heritier-08', 'piece-identite-heritier-09',
                'piece-identite-heritier-10', 'piece-identite-mandataire'
            ]
        },
        'titre-sejour-recto': {
            name: 'titre-sejour-recto',
            label: 'Titre de séjour recto',
            type: [
                'piece-identite', 'piece-identite-acquereur',
                'piece-identite-co-acquereur', 'piece-identite-representant',
                'piece-identite-cotitulaire', 'piece-identite-titulaire',
                'piece-identite-hebergeur', 'piece-identite-heritier-01',
                'piece-identite-heritier-02', 'piece-identite-heritier-03',
                'piece-identite-heritier-04', 'piece-identite-heritier-05',
                'piece-identite-heritier-06', 'piece-identite-heritier-07',
                'piece-identite-heritier-08', 'piece-identite-heritier-09',
                'piece-identite-heritier-10', 'piece-identite-mandataire'
            ]
        },
        'titre-sejour-verso': {
            name: 'titre-sejour-verso',
            label: 'Titre de séjour verso',
            type: [
                'piece-identite', 'piece-identite-acquereur',
                'piece-identite-co-acquereur', 'piece-identite-representant',
                'piece-identite-cotitulaire', 'piece-identite-titulaire',
                'piece-identite-hebergeur', 'piece-identite-heritier-01',
                'piece-identite-heritier-02', 'piece-identite-heritier-03',
                'piece-identite-heritier-04', 'piece-identite-heritier-05',
                'piece-identite-heritier-06', 'piece-identite-heritier-07',
                'piece-identite-heritier-08', 'piece-identite-heritier-09',
                'piece-identite-heritier-10', 'piece-identite-mandataire'
            ]
        },
        'ancien-certificat-immatriculation': {
            name: 'ancien-certificat-immatriculation',
            label: 'Ancien certificat d\'immatriculation',
            type: ['certificat-immatriculation']
        },
        'certificat-immatriculation-vierge': {
            name: 'certificat-immatriculation-vierge',
            label: 'Certificat d\'immatriculation vierge (ni signé ni tamponné ni daté)',
            type: true
        },
        'certificat-immatriculation-etranger': {
            name: 'certificat-immatriculation-etranger',
            label: "Certificat d'immatriculation étranger",
            type: true
        },
        'mandat': {
            name: 'mandat',
            label: 'Mandat',
            type: true
        },
        'document-cerfa': {
            name: 'document-cerfa',
            label: 'Document cerfa',
            type: true
        },
        'mandat-13757-02': {
            name: 'mandat-13757-02',
            label: 'Mandat Cerfa 13757*02',
            type: ['mandat']
        },
        'mandat-13757-03': {
            name: 'mandat-13757-03',
            label: 'Mandat Cerfa 13757*03',
            type: ['mandat']
        },
        'cerfa-13750-05': {
            name: 'cerfa-13750-05',
            label: 'Cerfa 13750-05',
            type: ['document-cerfa']
        },
        'cerfa-13753-04': {
            name: 'cerfa-13753-04',
            label: 'Cerfa 13753-04',
            type: ['document-cerfa']
        },
        'cerfa-13751-02': {
            name: 'cerfa-13751-02',
            label: 'Cerfa 13751-02',
            type: ['document-cerfa']
        },
        'cerfa-15776-01': {
            name: 'cerfa-15776-01',
            label: 'Cerfa 15776-01',
            type: ['document-cerfa']
        },

        'quittance-loyer': {
            type: ['justificatif-domicile', 'justificatif-domicile-hebergeur'],
            name: 'quittance-loyer',
            label: 'Quittance de loyer éditée par une agence immatriculée au RCS'
        },
        'avis-impots': {
            type: ['justificatif-domicile', 'justificatif-domicile-hebergeur'],
            name: 'avis-impots',
            label: 'Dernier avis d\'imposition ou de non imposition'
        },
        'quittance-gaz': {
            type: ['justificatif-domicile', 'justificatif-domicile-hebergeur'],
            name: 'quittance-gaz',
            label: 'Quittance de gaz'
        },
        'quittance-electricite': {
            type: ['justificatif-domicile', 'justificatif-domicile-hebergeur'],
            name: 'quittance-electricite',
            label: 'Quittance d\'électricité'
        },
        'facture-telephone': {
            type: ['justificatif-domicile', 'justificatif-domicile-hebergeur'],
            name: 'facture-telephone',
            label: 'Facture de téléphone fixe ou portable'
        },
        'facture-internet': {
            type: ['justificatif-domicile', 'justificatif-domicile-hebergeur'],
            name: 'facture-internet',
            label: 'Facture d\'un fournisseur d\'accès à Internet'
        },
        'attestation-energie': {
            type: ['justificatif-domicile', 'justificatif-domicile-hebergeur'],
            name: 'attestation-energie',
            label: 'Attestation d\'un fournisseur d\'énergie (EDF, Engie, ...)'
        },
        'attestation-assurance-habitation': {
            type: ['justificatif-domicile', 'justificatif-domicile-hebergeur'],
            name: 'attestation-assurance-habitation',
            label: 'Attestation d\'assurance habitation'
        },
        'accuse-enregistrement': {
            type: ['document'],
            name: 'accuse-enregistrement',
            label: 'Accusé d\'enregistrement'
        },
        'attestation-hebergement': {
            name: 'attestation-hebergement',
            label: 'Attestation d\'hébergement',
            type: true
        },
        'courrier-heberge': {
            name: 'courrier-heberge',
            label: 'Un courrier ( peu importe ) au nom de l\'hébergé datant de moins de 6 mois',
            type: true
        },
        'photo-kilometrage': {
            name: 'photo-kilometrage',
            label: 'Photo du compteur kilométrique',
            type: ['photo'],
            optional: true
        },
        'photo-client': {
            name: 'photo-client',
            label: 'Photo client (prendre un selfie pour vérifier votre identité)',
            type: ['photo'],
            optional: true
        },
        'photo-acquereur': {
            name: 'photo-acquereur',
            label: "Photo de l\'acquéreur (un selfie pour vérifier l'identité)",
            type: ['photo']
        },
        'photo-titulaire': {
            name: 'photo-titulaire',
            label: "Photo du titulaire (un selfie pour vérifier l'identité)",
            type: ['photo']
        },
        'fiche-identification-vehicule': {
            name: 'fiche-identification-vehicule',
            label: 'Fiche d\'identification du véhicule',
            type: true,
            optional: true
        },
        'certificat-situation-administrative-detaille': {
            name: 'certificat-situation-administrative-detaille',
            label: 'Certificat de situation administrative détaillé',
            type: ['certificat-situation-administrative'],
            optional: true
        },
        'attestation-honneur': {
            type: true,
            name: 'attestation-honneur',
            label: 'Attestation sur l\'honneur'
        },
        'acte-deces': {
            type: true,
            name: 'acte-deces',
            label: 'Acte de décès'
        },
        'acte-notarie': {
            type: true,
            name: 'acte-notarie',
            label: 'Acte notarié'
        },
        'brevet-securite-routiere': {
            type: true,
            name: 'brevet-securite-routiere',
            label: 'Brevet de sécurité routière (BSR)'
        },
        'certificat-moto-125cm': {
            type: true,
            name: 'certificat-moto-125cm',
            label: 'Le certificat moto 125cm3 (Permis A1)'
        },
        'facture-leasing': {
            type: true,
            name: 'facture-leasing',
            label: 'Facture d\'achat du leasing'
        },
        'facture-achat': {
            type: true,
            name: 'facture-achat',
            label: 'Facture d\'achat'
        },
        'lettre-desistement': {
            type: true,
            name: 'lettre-desistement',
            label: 'Lettre de désistement'
        },
        'recepisse-permis-conduire': {
            type: true,
            name: 'recepisse-permis-conduire',
            label: 'Le récépissé du permis de conduire'
        },
        'certificat-residence': {
            type: true,
            name: 'certificat-residence',
            label: 'Certificat de résidence'
        },
        'recepisse-piece-identite': {
            type: true,
            name: 'recepisse-piece-identite',
            label: "Le récépissé de renouvellement de pièce d'identité"
        },
        'livret-famille': {
            type: true,
            name: 'livret-famille',
            label: 'Livret de famille'
        },
        'acte-mariage': {
            type: true,
            name: 'acte-mariage',
            label: 'Acte de mariage'
        },
        'certificat-conformite': {
            type: true,
            name: 'certificat-conformite',
            label: 'Certificat de conformité'
        },
        'certificat-conformite-recto': {
            name: 'certificat-conformite-recto',
            label: 'Certificat de conformité recto',
            type: ['certificat-conformite']
        },
        'certificat-conformite-verso': {
            name: 'certificat-conformite-verso',
            label: 'Certificat de conformité verso',
            type: ['certificat-conformite']
        },
        'certificat-conformite-barre': {
            name: 'certificat-conformite-barre',
            label: 'Certificat de conformité barré rouge',
            type: ['certificat-conformite']
        },
        'quitus-fiscal': {
            type: true,
            name: 'quitus-fiscal',
            label: 'Quitus fiscal ou le certificat 846 A'
        },
        'carte-grise-etrangere-01': {
            type: true,
            name: 'carte-grise-etrangere-01',
            label: 'Certificat immatriculation étranger - document 01'
        },
        'carte-grise-etrangere-02': {
            type: true,
            name: 'carte-grise-etrangere-02',
            label: 'Certificat immatriculation étranger - document 02'
        },
        'carte-grise-etrangere-03': {
            type: true,
            name: 'carte-grise-etrangere-03',
            label: 'Certificat immatriculation étranger - document 03'
        },
        'carte-grise-etrangere-04': {
            type: true,
            name: 'carte-grise-etrangere-04',
            label: 'Certificat immatriculation étranger - document 04'
        },
        'carte-grise-etrangere-05': {
            type: true,
            name: 'carte-grise-etrangere-05',
            label: 'Certificat immatriculation étranger - document 05'
        },
        'carte-grise-etrangere-06': {
            type: true,
            name: 'carte-grise-etrangere-06',
            label: 'Certificat immatriculation étranger - document 06'
        },
        'carte-grise-etrangere-07': {
            type: true,
            name: 'carte-grise-etrangere-07',
            label: 'Certificat immatriculation étranger - document 07'
        },
        'carte-grise-etrangere-08': {
            type: true,
            name: 'carte-grise-etrangere-08',
            label: 'Certificat immatriculation étranger - document 08'
        },
        'carte-grise-etrangere-09': {
            type: true,
            name: 'carte-grise-etrangere-09',
            label: 'Certificat immatriculation étranger - document 09'
        },
        'carte-grise-etrangere-10': {
            type: true,
            name: 'carte-grise-etrangere-10',
            label: 'Certificat immatriculation étranger - document 10'
        },
        'traduction-permis-conduire': {
            type: true,
            name: 'traduction-permis-conduire',
            label: 'Traduction du permis de conduire'
        },
        'fiche-immobilisation': {
            type: true,
            name: 'fiche-immobilisation',
            label: "Fiche d'immobilisation"
        },
        'declaration-perte-certificat-immatriculation': {
            type: true,
            name: 'declaration-perte-certificat-immatriculation',
            label: "Déclaration de perte de certificat d'immatriculation"
        },
        'declaration-vol-certificat-immatriculation': {
            type: true,
            name: 'declaration-vol-certificat-immatriculation',
            label: "Déclaration de vol de certificat d'immatriculation"
        },
        'declaration-perte-permis-conduire': {
            type: true,
            name: 'declaration-perte-permis-conduire',
            label: "Déclaration de perte du permis de conduire"
        },
        'proces-verbal-police': {
            type: true,
            name: 'proces-verbal-police',
            label: 'Procès-verbal de la police ou de gendarmerie'
        },
        'attestation-l5e': {
            type: true,
            name: 'attestation-l5e',
            label: 'Attestation de formation à la conduite motocyclettes légères et de véhicules L5E'
        },
        'attestation-l5e-recto': {
            type: ['attestation-l5e'],
            name: 'attestation-l5e-recto',
            label: 'Attestation de formation L5E recto'
        },
        'attestation-l5e-verso': {
            type: ['attestation-l5e'],
            name: 'attestation-l5e-verso',
            label: 'Attestation de formation L5E verso'
        },
        'mandat-leasing': {
            type: true,
            name: 'mandat-leasing',
            label: "Le mandat d'immatriculation établi par la société de leasing (Cerfa 13757*03) "
        },
        'certificat-immatriculation-etranger-recto': {
            type: ['certificat-immatriculation-etranger'],
            name: 'certificat-immatriculation-etranger-recto',
            label: "Certificat d'immatriculation étranger recto"
        },
        'certificat-immatriculation-etranger-verso': {
            type: ['certificat-immatriculation-etranger'],
            name: 'certificat-immatriculation-etranger-verso',
            label: "Certificat d'immatriculation étranger verso"
        },
        'procuration': {
            type: true,
            name: 'procuration',
            label: 'Lettre de procuration'
        },
        'piece-identite-mandataire': {
            name: 'piece-identite-mandataire',
            label: "Pièce d'identité du mandataire",
            type: true
        }
    };

    function getAllProcedureTypes() {
        return [
            {
                name: CERTIFICAT_IMMATRICULATION,
                label: "Changement du certificat d'immatriculation"
            },
            {
                name: CHANGEMENT_ADRESSE,
                label: "Changement d'adresse"
            },
            {
                name: DUPLICATA,
                label: 'Duplicata'
            },
            {
                name: DECLARATION_ACHAT,
                label: "Déclaration d'achat"
            },
            {
                name: DECLARATION_CESSION,
                label: 'Déclaration de cession'
            },
            {
                name: CERTIFICAT_SITUATION_ADMINISTRATIVE,
                label: 'Obtention un certificat de non-gage',
                disabled: true
            },
            {
                name: FICHE_IDENTIFICATION_VEHICULE,
                label: "Fiche d'identification du véhicule",
                disabled: true
            },
            {
                name: CERTIFICAT_IMMATRICULATION_WW,
                label: "Immatriculation WW d'un véhicule d'occasion importé"
            },
            {
                name: CERTIFICAT_IMMATRICULATION_VN,
                label: "Immatriculation d'un véhicule neuf",
                disabled: true
            },
            {
                name: CERTIFICAT_IMMATRICULATION_VI,
                label: "Immatriculation définitive d'un véhicule importé",
                disabled: true
            }
        ];
    }

    function getProcedureName(procedureType = null) {
        if (procedureType === null) {
            return null;
        }
        switch (procedureType) {
            case 'creation-compte-professionnel':
                return "Création d'un compte professionnel ou partenaire";
            case CERTIFICAT_IMMATRICULATION:
                return "Changement du certificat d'immatriculation";
            case CERTIFICAT_IMMATRICULATION_WW:
                return "Immatriculation WW d'un véhicule d'occasion importé";
            case CERTIFICAT_IMMATRICULATION_VI:
                return "Immatriculation définitive d'un véhicule importé";
            case VENTE_EN_LIGNE:
                return 'Vente 100% en ligne';
            case DECLARATION_ACHAT:
                return "Déclaration d'achat";
            case DECLARATION_CESSION:
                return 'Déclaration de cession';
            case CERTIFICAT_SITUATION_ADMINISTRATIVE:
                return 'Obtention un certificat de non-gage';
            case PLAQUES_IMMATRICULATION:
                return "Commande des plaques d'immatriculation";
            case CHANGEMENT_ADRESSE:
                return "Changement d'adresse";
            case DUPLICATA:
                return 'Duplicata';
            case FICHE_IDENTIFICATION_VEHICULE:
                return "Fiche d'identification du véhicule";
            case CERTIFICAT_IMMATRICULATION_VN:
                return "Immatriculation d'un véhicule neuf";
            default:
                return null;
        }//
    }
    function _getPriceName(price) {
        switch (price) {
            /* 0 */ // plaque aluminium rectangulaire
            case 'PlateAluminumRectangular':
                return 'Prix d\'une plaque aluminium rectangulaire';
                /* 1 */ // plaque aluminium carrée
            case 'PlateAluminumSquare':
                return 'Prix d\'une plaque aluminium carrée';
                /* 2 */ // plaque plexiglas rectangulaire
            case 'PlatePlexiglasRectangular':
                return 'Prix d\'une plaque plexiglas rectangulaire';
                /* 3 */ // plaque plexiglas carrée
            case 'PlatePlexiglasSquare':
                return 'Prix d\'une plaque plexiglas carrée';
                /* 4 */ // plaque plexiglas sans personnalisation
            case 'PlateNoneCustomization':
                return 'Prix d\'une plaque plexiglas sans personnalisation';
                /* 5 */ // plaque plexiglas avec personnalisation logo
            case 'PlateLogoCustomization':
                return 'Prix d\'une plaque plexiglas avec logo RCG';
                /* 6 */ // plaque plexiglas avec personnalisation texte
            case 'PlateTextCustomization':
                return 'Prix d\'une plaque plexiglas avec personnalisation texte';
                /* 7 */ // kit d'installation
            case 'PlatesInstallationKit':
                return 'Prix d\'un kit d\'installation';
                /* 8 */ // frais de port standard d'une commande des plaques
            case 'PlatesShippingStandard':
                return 'Frais de port standard d\'une commande des plaques';
                /* 9 */ // frais de port en point relais d'une commande des plaques
            case 'PlatesShippingRelay':
                return 'Frais de port en point relais d\'une commande des plaques';
                /* 10 */ // le prix d'une démarche de déclaration de cession
            case 'DeclarationCession':
                return 'Prix d\'une démarche de déclaration de cession';
                /* 11 */ // le prix d'une démarche de vente 100% en ligne
            case 'VenteEnLigne':
                return 'Prix d\'une démarche de vente 100% en ligne';
            case 'CertificatImmatriculation':
                return 'Prix d\'une démarche changement de certificat d\'immatriculation';
            case 'ChangementAdresse':
                return 'Prix d\'une démarche de changement d\'adresse';
        }
    }
    function _getPersonAge(person = {}, months = false) {
        if (person.birthDate === null) {
            return null;
        }
        const formatedBithDate = moment(person.birthDate, 'DD/MM/YYYY');
        const nbMonths = moment().diff(formatedBithDate, 'months', true);
        const nbYears = moment().diff(formatedBithDate, 'years');
        if (months) {
            return nbMonths;
        }
        return nbYears;
    }
    function _hasBirthInfos(person = {}){
        return person.birthDate !== null && person.birthPlace !== null;
    }
    function _getSubProcedure(procedure) {
        if (!procedure) {
            return null;
        }
        switch (procedure.type) {
            case DECLARATION_ACHAT:
                return procedure.DeclarationAchat;
            case CERTIFICAT_IMMATRICULATION:
                return procedure.CertificatImmatriculation;
            case CERTIFICAT_IMMATRICULATION_WW:
                return procedure.CertificatImmatriculationWW;
            case CERTIFICAT_IMMATRICULATION_VI:
                return procedure.CertificatImmatriculationVI;
            case DECLARATION_CESSION:
                return procedure.DeclarationCession;
            case DUPLICATA:
                return procedure.Duplicata;
            case CHANGEMENT_ADRESSE:
                return procedure.ChangementAddress;
            default:
                return null;
        }//
    }
    function _getDefaultPersonType(procedureType) {
        switch (procedureType) {
            case DECLARATION_ACHAT:
            case CERTIFICAT_IMMATRICULATION:
            case CERTIFICAT_IMMATRICULATION_WW:
            case CERTIFICAT_IMMATRICULATION_VI:
                return 'buyer';
            case DECLARATION_CESSION:
                return 'seller';
            case DUPLICATA:
            case CHANGEMENT_ADRESSE:
                return 'owner';
            default:
                return null;
        }//
    }
    function _getPersonName(person = {}, withTitle = false, withAge = false) {
        if (person === null) {
            return null;
        }
        if (person.personType === PERSONNE_MORALE) {
            return person.company + (person.siren ? ' ( ' + person.siren + ' ) ' : '');
        }
        if (person.personType === PERSONNE_PHYSIQUE) {
            return (withTitle ? _getPersonTitle(person) + ' ' : '') +
                    person.lastname + ' ' + person.firstname +
                    (withAge ? (typeof person.age !== 'undefined' ? ' ( ' + person.age + ' ans ) ' : '')
                            : '');
        }
        return (withTitle ? _getPersonTitle(person) + ' ' : '') +
                person.lastname + ' ' + person.firstname;
    }
    function _getPersonTypeLabel(person = {}){
        switch (person.personType) {
            case PERSONNE_PHYSIQUE:
                return 'Particulier';
            case PERSONNE_MORALE:
                return 'Personne morale';
            default:
                return null;
        }//
    }
    function _isCompany(person = {}){
        return person && person.personType === PERSONNE_MORALE;
    }
    function _getPersonTitle(person = {}){
        switch (person.title) {
            case 'm':
                return 'M.';
            case 'mme':
                return 'M<sup>me</sup>';
            case 'mlle':
                return 'M<sup>lle</sup>';
            default:
                return '';
        }//
    }
    function _getDocumentByType(documents = [], type = null, subType = null) {
        if (documents.length === 0 || type === null) {
            return null;
        }
        if (typeof type === 'string') {
            type = [type];
        }
        for (let i = 0; i < documents.length; i++) {
            let document = documents[i];
            if (type.indexOf(document.type) > -1) {
                if (subType !== null &&
                        document.subType === subType) {
                    return document;
                } else {
// if document has a recto and verso, 
// we should only get the "recto"
                    if (document.field.indexOf('recto') > -1 ||
                            (document.field.indexOf('recto') +
                                    document.field.indexOf('verso') === -2)) {
                        return document;
                    }
                }
            }
        }
        return null;
    }

    function _getDocValidationQuestions(document = {}, procedureType = null){
        let documentType = document.type;
        let documentSubType = document.subType, data = [];
        let fields = [
            /* 0  */ {label: 'Type correct', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 1  */ {label: 'Qualité correcte', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 2  */ {label: 'Signature', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 3  */ {label: 'Datée', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 4  */ {label: 'Barrée', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 5  */ {label: 'Date', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 6  */ {label: 'Date de moins de 6 mois', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 7  */ {label: 'Date de moins de 3 mois', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 8  */ {label: 'Date de moins de 1 an', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 9  */ {label: 'Valable / Valide', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 10 */ {label: 'Date et heure de cession sont correctes', defaultText: 'Non', checkedText: 'Oui', value: false},
            /* 11 */ {label: 'Surcharge', defaultText: 'Oui', checkedText: 'Non', value: false}
        ];
        data.push(fields[0]);
        data.push(fields[1]);
        switch (documentType) {
            case 'certificat-immatriculation':
                if (procedureType === CHANGEMENT_ADRESSE) {
                    // continue
                } else if (procedureType !== PLAQUES_IMMATRICULATION) {
                    data.push(fields[2]);
                    data.push(fields[3]);
                    data.push(fields[4]);
                }
                break;

            case 'certificat-cession':
            case 'autorisation-parentale':
                data.push(fields[2]);
                data.push(fields[5]);
                break;
            case 'controle-technique':
                if (procedureType === CHANGEMENT_ADRESSE) {
                    data.push(fields[9]);
                } else {
                    data.push(fields[6]);
                    data.push(fields[5]);
                }
                break;
            case 'kbis':
            case 'attestation-assurance':
                if (procedureType === CHANGEMENT_ADRESSE) {
                    data.push(fields[9]);
                } else {
                    data.push(fields[7]);
                }
                break;
            case 'piece-identite':
            case 'permis-conduire':
            case 'piece-identite-representant':
            case 'piece-identite-cotitulaire':
            case 'piece-identite-hebergeur':
                data.push(fields[9]);
                break;
            case 'justificatif-domicile':
                if (procedureType === CHANGEMENT_ADRESSE) {
                    data.push(fields[6]);
                } else {
                    if (documentSubType === 'avis-impots' ||
                            'attestation-assurance-habitation' === documentSubType) {
                        data.push(fields[8]);
                    } else {
                        data.push(fields[7]);
                    }
                }
                break;
            case 'attestation-hebergement':
                if (procedureType === CHANGEMENT_ADRESSE) {
                    data.push(fields[6]);
                    data.push(fields[2]);
                } else {
                    data.push(fields[7]);
                    data.push(fields[2]);
                    data.push(fields[5]);
                }
                break;
        }//

        if (documentType !== 'controle-technique' && documentType !== 'photo' &&
                procedureType !== PLAQUES_IMMATRICULATION && procedureType !== CHANGEMENT_ADRESSE) {
            data.push(fields[11]);
        }

        return data;
    }

    function _getDocumentPosition(document = {}, procedureType = null) {
        const documentType = typeof document === 'string' ? document : document.type;
        switch (documentType) {
            case 'certificat-immatriculation-etranger':
                return 1;
            case 'quitus-fiscal':
                return 3;
            case 'certificat-cession':
                if (procedureType === CERTIFICAT_IMMATRICULATION_WW) {
                    return 2;
                }
                return 1;
            case 'certificat-immatriculation':
                return 2;
            case 'controle-technique':
                return 3;
            case 'attestation-assurance':
                return 4;
            case 'declaration-achat':
                return 5;
            case 'permis-conduire':
                return 6;
            case 'piece-identite':
                if (procedureType === CHANGEMENT_ADRESSE) {
                    return 1;
                }
                return 7;
            case 'justificatif-domicile':
                return 8;
            case 'piece-identite-hebergeur':
                return 9;
            case 'attestation-hebergement':
                return 10;
            case 'justificatif-domicile-hebergeur':
                return 11;
            case 'kbis':
                return 12;
            case 'piece-identite-representant':
                return 13;
            case 'autorisation-parentale':
                return 14;
            case 'piece-identite-co-acquereur':
                return 15;
            case 'document-cerfa':
                return 16;
            case 'photo':
                return 17;
            default:
                return 20;
        }//
    }

    function _getDocumentOrder(documentType = '', documentField) {
        switch (documentType) {
            case 'piece-identite':
                return 1;
            case 'permis-conduire':
            case 'piece-identite-representant':
                return 2;
            case 'piece-identite-hebergeur':
                return 3;
            case 'certificat-immatriculation':
                return 4;
            default:
                return 5;
        }//
    }

    function _getNewDocumentPosition(document = {}, procedureType = null) {
        const documentType = typeof document === 'string' ? document : document.type;
        switch (documentType) {

            case 'certificat-immatriculation-etranger':
                return 1;
            case 'quitus-fiscal':
                return 3;
            case 'certificat-cession':
                if (procedureType === CERTIFICAT_IMMATRICULATION_WW) {
                    return 2;
                }
                return 1;
            case 'certificat-immatriculation':
                return 2;
            case 'declaration-achat':
                return 3;
            case 'controle-technique':
                return 4;
            case 'attestation-assurance':
                return 5;
            case 'permis-conduire':
                if (document.isRecto === true) {
                    return 6;
                }
                if (document.isVerso === true) {
                    return 6.5;
                }
                return 6;
            case 'piece-identite':
                if (procedureType === CHANGEMENT_ADRESSE) {
                    return 1;
                }
                return 7;
            case 'justificatif-domicile':
                return 8;
            case 'piece-identite-hebergeur':
                if (document.isRecto === true) {
                    return 9;
                }
                if (document.isVerso === true) {
                    return 9.5;
                }
                return 9;
            case 'attestation-hebergement':
                return 10;
            case 'justificatif-domicile-hebergeur':
                return 11;
            case 'kbis':
                return 12;
            case 'piece-identite-representant':
                if (document.isRecto === true) {
                    return 13;
                }
                if (document.isVerso === true) {
                    return 13.5;
                }
                return 13;
            case 'autorisation-parentale':
                return 14;
            case 'piece-identite-co-acquereur':
                if (document.isRecto === true) {
                    return 15;
                }
                if (document.isVerso === true) {
                    return 15.5;
                }
                return 15;
            case 'document-cerfa':
                return 16;
            case 'photo':
                return 17;
            default:
                return 20;
        }//
    }

    function _getCIMConcordance(documents = [], document = {}) {
        let data = {
            documentLabel: '',
            document: null,
            fields: []
        };
        let documentType = document.type;
        let documentField = document.field || document.subType;
        switch (documentType) {

            case 'certificat-cession':
                data.document = _getDocumentByType(documents, 'certificat-immatriculation');
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                data.fields.push({
                    label: 'Noms identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Signatures identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Dates',
                    value: false
                });
                data.fields.push({
                    label: 'Immatriculations',
                    value: false
                });
                data.fields.push({
                    label: 'Informations du véhicule',
                    value: false
                });
                break;
            case 'declaration-achat':
                data.document = _getDocumentByType(documents, 'certificat-immatriculation');
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                data.fields.push({
                    label: 'Noms identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Signatures identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Dates',
                    value: false
                });
                data.fields.push({
                    label: 'Immatriculations',
                    value: false
                });
                data.fields.push({
                    label: 'Informations du véhicule',
                    value: false
                });
                break;
            case 'permis-conduire':
            case 'piece-identite':
                if (documentField === 'passport' || documentField.substr(-5, 5) === 'recto') {
                    data.document = _getDocumentByType(documents, 'certificat-cession');
                    if (data.document !== null) {
                        data.document.isLeft = true;
                    }
                    data.fields.push({
                        label: 'Noms identiques',
                        value: false
                    });
                } else {
                    data = null;
                }
                break;
            case 'controle-technique':
            case 'attestation-assurance':
                data.document = _getDocumentByType(documents, 'certificat-cession');
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                data.fields.push({
                    label: 'Immatriculations',
                    value: false
                });
                break;
                //case 'justificatif-domicile':
            case 'photo':
                data.document = _getDocumentByType(documents, ['piece-identite', 'permis-conduire']);
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                if (documentType === 'photo') {
                    data.fields.push({
                        label: 'Photos identiques',
                        value: false
                    });
                } else {
                    data.fields.push({
                        label: 'Noms identiques',
                        value: false
                    });
                }
                break;
            case 'autorisation-parentale':
                data.document = _getDocumentByType(documents, 'piece-identite-representant');
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                data.fields.push({
                    label: 'Nom et prénom identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Signature identique',
                    value: false
                });
                break;
            case 'attestation-hebergement':
                data.document = _getDocumentByType(documents, 'piece-identite-hebergeur');
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                data.fields.push({
                    label: 'Nom et prénom identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Signature identique',
                    value: false
                });
                break;
            default:
                data = null;
        }
        if (data !== null && data.document === null) {
            data = null;
        }
        return data;
    }

    function _getCIWWConcordance(documents = [], document = {}) {

        let data = {
            documentLabel: '',
            document: null,
            fields: []
        };
        let documentType = document.type;
        let documentField = document.field || document.subType;

        switch (documentType) {

            case 'certificat-cession':
                data.document = _getDocumentByType(documents, 'certificat-immatriculation-etranger');
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                data.fields.push({
                    label: 'Informations du véhicule',
                    value: false
                });
                break;

            case 'quitus-fiscal':
            case 'facture-achat':
                data.document = _getDocumentByType(documents, 'certificat-immatriculation-etranger');
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                data.fields.push({
                    label: 'Informations du véhicule',
                    value: false
                });
                break;

            case 'permis-conduire':
            case 'piece-identite':
                if (documentField === 'passport' || documentField.substr(-5, 5) === 'recto') {
                    data.document = _getDocumentByType(documents, 'certificat-cession');
                    if (data.document !== null) {
                        data.document.isLeft = true;
                    }
                    data.fields.push({
                        label: 'Noms identiques',
                        value: false
                    });
                } else {
                    data = null;
                }
                break;

            case 'attestation-hebergement':
                data.document = _getDocumentByType(documents, 'piece-identite-hebergeur');
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                data.fields.push({
                    label: 'Nom et prénom identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Signature identique',
                    value: false
                });
                break;

            default:
                data = null;
        }
        if (data !== null && data.document === null) {
            data = null;
        }
        return data;
    }

    function _getConcordance(documents = [], document = {}) {
        let data = {
            documentLabel: '',
            document: null,
            fields: []
        }, documentType = document.type;
        switch (documentType) {

            case 'certificat-immatriculation':
                data.documentLabel = 'au ' + _getDocumentTypeLabel('certificat-cession');
                data.document = _getDocumentByType(documents, 'certificat-cession');
                data.fields.push({
                    label: 'Noms identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Signatures identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Dates',
                    value: false
                });
                data.fields.push({
                    label: 'Immatriculations',
                    value: false
                });
                data.fields.push({
                    label: 'Informations du véhicule',
                    value: false
                });
                break;
            case 'controle-technique':
            case 'attestation-assurance':
                data.documentLabel = 'au ' + _getDocumentTypeLabel('certificat-immatriculation');
                data.document = _getDocumentByType(documents, 'certificat-immatriculation');
                data.fields.push({
                    label: 'Immatriculations',
                    value: false
                });
                break;
            case 'justificatif-domicile':
            case 'photo':
                data.documentLabel = _getDocumentTypeLabel('piece-identite');
                data.document = _getDocumentByType(documents, ['piece-identite', 'permis-conduire']);
                if (documentType === 'photo') {
                    data.fields.push({
                        label: 'Photos identiques',
                        value: false
                    });
                } else {
                    data.fields.push({
                        label: 'Noms identiques',
                        value: false
                    });
                }
                break;
            case 'autorisation-parentale':
                data.documentLabel = _getDocumentTypeLabel('piece-identite-representant');
                data.document = _getDocumentByType(documents, 'piece-identite-representant');
                data.fields.push({
                    label: 'Nom et prénom identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Signature identique',
                    value: false
                });
                break;
            case 'attestation-hebergement':
                data.documentLabel = _getDocumentTypeLabel('piece-identite-hebergeur');
                data.document = _getDocumentByType(documents, 'piece-identite-hebergeur');
                data.fields.push({
                    label: 'Nom et prénom identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Signature identique',
                    value: false
                });
                break;
            default:
                data = null;
        }
        return data;
    }

    function _getCADConcordance(documents = [], document = {}) {
        let data = {
            documentLabel: '',
            document: null,
            fields: []
        }, documentType = document.type;
        switch (documentType) {

            case 'certificat-immatriculation':
                data.documentLabel = 'au ' + _getDocumentTypeLabel('piece-identite');
                data.document = _getDocumentByType(documents, 'piece-identite');
                data.fields.push({
                    label: 'Noms identiques',
                    value: false
                });
                break;
            case 'controle-technique':
            case 'attestation-assurance':
                data.documentLabel = 'au ' + _getDocumentTypeLabel('certificat-immatriculation');
                data.document = _getDocumentByType(documents, 'certificat-immatriculation');
                data.fields.push({
                    label: 'Immatriculations',
                    value: false
                });
                break;
            case 'justificatif-domicile':
            case 'photo':
                data.documentLabel = _getDocumentTypeLabel('piece-identite');
                data.document = _getDocumentByType(documents, 'piece-identite');
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                if (documentType === 'photo') {
                    data.fields.push({
                        label: 'Photos identiques',
                        value: false
                    });
                } else {
                    data.fields.push({
                        label: 'Noms identiques',
                        value: false
                    });
                }
                break;
            case 'attestation-hebergement':
                data.documentLabel = _getDocumentTypeLabel('piece-identite-hebergeur');
                data.document = _getDocumentByType(documents, 'piece-identite-hebergeur');
                if (data.document !== null) {
                    data.document.isLeft = true;
                }
                data.fields.push({
                    label: 'Nom et prénom identiques',
                    value: false
                });
                data.fields.push({
                    label: 'Signature identique',
                    value: false
                });
                break;
            default:
                data = null;
        }
        return data;
    }

    function _hasAddress(person = {}){
        return person && person.Address !== null;
    }

    function _getDocumentTypes(byDocumentType = null) {

        let documents = [];
        for (let type1 in documentTypes) {
            let documentType1 = documentTypes[type1];
            documentType1.hasSubTypes = false;
            for (let type2 in documentTypes) {
                let documentType2 = documentTypes[type2];
                if (documentType2.type !== true &&
                        documentType2.type.indexOf(documentType1.name) > -1) {
                    documentType1.hasSubTypes = true;
                    break;
                }
            }
        }

        for (let type in documentTypes) {
            let documentType = documentTypes[type];
            if (byDocumentType !== null) {
                if ((documentType.type !== true &&
                        documentType.type.indexOf(byDocumentType) > -1) ||
                        documentType.name === byDocumentType) {
                    documents.push(documentType);
                }
            } else {
                if (documentType.type === true) {
                    documents.push(documentType);
                }
            }
        }
        return documents.sort((a, b) => {
            if (a.label > b.label)
                return 1;
            else if (a.label < b.label)
                return -1;
            else
                return 0;
        });
    }
    function openDocumentsViewer(document, $scope) {

        let doc2 = null;
        if (document.concordance !== null && document.concordance.document !== null) {
            let referenceDocument = document.concordance.document;
            doc2 = {
                status: referenceDocument.status,
                type: referenceDocument.typeLabel,
                subType: referenceDocument.label,
                fileType: referenceDocument.fileType,
                url: referenceDocument.url,
                fields: document.concordance.fields,
                allChecked: false
            };
        }

        let locals = {
            data: {
                procedure: $scope.item,
                procedureType: $scope._type,
                client: $scope.client,
                vehicle: $scope.vehicle,
                seller: $scope.seller,
                buyer: $scope.buyer,
                owner: $scope.owner,
                document: document,
                editedSeller: $scope.editedSeller,
                editedBuyer: $scope.editedBuyer,
                editedOwner: $scope.editedOwner,
                editSeller: $scope.editSeller,
                editBuyer: $scope.editBuyer,
                editOwner: $scope.editOwner,
                ownerOldAddress: $scope.oldAddress,
                ownerNewAddress: $scope.owner ? $scope.owner.Address : {},
                // current document
                doc1: {
                    type: document.typeLabel,
                    subType: document.label,
                    fileType: document.fileType,
                    url: document.url,
                    needConcordanceVerif: document.concordance !== null
                },
                // reference document for concordance
                doc2: doc2
            }
        };
        $mdDialog.show({
            multiple: true,
            templateUrl: TEMPLATES_DIR + 'document.viewer.html',
            locals: locals,
            controller: ($scope, $timeout, $mdDialog, data) => {

                $scope.step = 2;
                $scope.viewers = [];
                $scope.procedure = data.procedure;
                $scope.procedureType = data.procedureType;
                $scope.client = data.client;
                $scope.seller = data.seller;
                $scope.buyer = data.buyer;
                $scope.owner = data.owner;
                $scope.ownerOldAddress = data.ownerOldAddress;
                $scope.ownerNewAddress = data.ownerNewAddress;
                $scope.vehicle = data.vehicle;
                $scope.document = data.document;
                $scope.doc1 = data.doc1;
                $scope.doc2 = data.doc2;
                //------------------------

                $scope.concordance = $scope.doc1 !== null && $scope.doc2 !== null;
                if ($scope.concordance) {
                    $scope.doc1.allChecked = true;
                    $scope.doc2.allChecked = true;
                    $scope.step = 1;
                    setTimeout(() => {
                        if ($scope.doc2 && $scope.doc2.fileType === 'image') {
                            let img2Src = getElementById('image2');
                            img2Src.style.opacity = 0;
                            const viewer2 = new Viewer(img2Src, imageViewerParams);
                            $scope.viewers.push(viewer2);
                        }
                        $timeout(() => {
                            window.dispatchEvent(new Event('resize'));
                        });
                    }, 123);
                }

                $scope.pdf1 = null;
                $scope.pdf2 = null;
                $scope.editType = ($event, seller) => {

                    let item = $(getTargetItem($event)),
                            value = $(item).data('value'),
                            field = $(item).data('field'),
                            personSubType = $(item).data('sub-type');
                    if (field === 'personType') {
                        if (personSubType === 'seller') {
                            $scope.editSeller = true;
                            $scope.editBuyer = $scope.editOwner = false;
                            $scope.editedSeller = {
                                type: personSubType,
                                personType: value === PERSONNE_PHYSIQUE ? PERSONNE_MORALE : PERSONNE_PHYSIQUE,
                                isPro: value === PERSONNE_MORALE
                            };
                        } else if (personSubType === 'buyer') {
                            $scope.editBuyer = true;
                            $scope.editSeller = $scope.editOwner = false;
                            $scope.editedBuyer = {
                                type: personSubType,
                                personType: value === PERSONNE_PHYSIQUE ? PERSONNE_MORALE : PERSONNE_PHYSIQUE,
                                isPro: value === PERSONNE_MORALE
                            };
                        } else if (personSubType === 'owner') {
                            $scope.editOwner = true;
                            $scope.editSeller = $scope.editBuyer = false;
                            $scope.editedOwner = {
                                type: personSubType,
                                personType: value === PERSONNE_PHYSIQUE ? PERSONNE_MORALE : PERSONNE_PHYSIQUE,
                                isPro: value === PERSONNE_MORALE
                            };
                        }
                    }

                    let locals = {
                        editedSeller: $scope.editedSeller,
                        editedBuyer: $scope.editedBuyer,
                        editedOwner: $scope.editedOwner,
                        editBuyer: $scope.editBuyer,
                        editOwner: $scope.editOwner,
                        editSeller: $scope.editSeller,
                        procedure: $scope.procedure,
                        seller: $scope.seller,
                        buyer: $scope.buyer,
                        owner: $scope.owner
                    };
                    $mdDialog.show({
                        parent: angular.element(document.body),
                        targetEvent: event,
                        locals: locals,
                        multiple: true,
                        draggable: true,
                        templateUrl: TEMPLATES_DIR + 'edit-type.html',
                        controller: (
                                $scope, $mdDialog, toast, $location, editedSeller, editSeller,
                                editBuyer, editedBuyer, editOwner, editedOwner, procedure, buyer, seller, owner
                                ) => {
                            $scope.editSeller = editSeller;
                            $scope.editedSeller = editedSeller;
                            $scope.editBuyer = editBuyer;
                            $scope.editedBuyer = editedBuyer;
                            $scope.editOwner = editOwner;
                            $scope.editedOwner = editedOwner;
                            $scope.procedure = procedure;
                            $scope.seller = seller;
                            $scope.buyer = buyer;
                            $scope.owner = owner;
                            $scope.cancelSellerEdit = () => {
                                $scope.editSeller = false;
                                $scope.editedSeller = {};
                                $timeout(() => {
                                    $scope.$apply();
                                });
                                $mdDialog.hide();
                            };
                            $scope.saveEditedTypeSeller = () => {
                                if ($scope.editSeller) {
                                    procedureService.updateItem($scope.procedure.ProcedureId, 'person', $scope.seller._id, $scope.editedSeller).then(() => {
                                        toast.show('Les données ont été bien mises à jour.', 'success');
                                        setTimeout(() => {
                                            $window.location.href = $location.path() + "/?tab=2&&doc=" + document._id;
                                        }, 500);
                                    }).catch((err) => {
                                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                                    });
                                }
                            };
                            $scope.cancelBuyerEdit = () => {
                                $scope.editBuyer = false;
                                $scope.editedBuyer = {};
                                $timeout(() => {
                                    $scope.$apply();
                                });
                                $mdDialog.hide();
                            };
                            $scope.saveEditedTypeBuyer = () => {
                                if ($scope.editBuyer) {
                                    procedureService.updateItem($scope.procedure.ProcedureId, 'person', $scope.buyer._id, $scope.editedBuyer).then(() => {
                                        toast.show('Les données ont été bien mises à jour.', 'success');
                                        setTimeout(() => {
                                            $window.location.href = $location.path() + "/?tab=2&&doc=" + document._id;
                                        }, 3000);
                                    }).catch((err) => {
                                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                                    });
                                }
                            };
                            $scope.cancelOwnerEdit = () => {
                                $scope.editOwner = false;
                                $scope.editedOwner = {};
                                $timeout(() => {
                                    $scope.$apply();
                                });
                                $mdDialog.hide();
                            };
                            $scope.saveEditedTypeOwner = () => {
                                if ($scope.editOwner) {
                                    procedureService.updateItem($scope.procedure.ProcedureId, 'person', $scope.owner._id, $scope.editedOwner).then(() => {
                                        toast.show('Les données ont été bien mises à jour.', 'success');
                                        setTimeout(() => {
                                            $window.location.href = $location.path() + "/?tab=2&&doc=" + document._id;
                                        }, 3000);
                                    }).catch((err) => {
                                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                                    });
                                }
                            };
                        }
                    });
                };
                $scope.checkValidationQuestion = (doc) => {
                    let checked = true;
                    for (let i = 0; i < doc.validation.length; i++) {
                        checked = checked && doc.validation[i].value;
                    }
                    doc.allChecked = checked;
                };
                $scope.getDocumentFieldsStatus = (doc) => {
                    let checked = true;
                    for (let i = 0; i < doc.fields.length; i++) {
                        checked = checked && doc.fields[i].value;
                    }
                    if ($scope.doc2) {
                        $scope.doc2.allChecked = checked;
                    } else {
                        doc.allChecked = checked;
                    }
                };
                const imageViewerParams = {
                    inline: true,
                    backdrop: false,
                    button: false,
                    navbar: false,
                    title: false,
                    keyboard: false,
                    loading: true,
                    toolbar: {
                        zoomIn: {show: true},
                        zoomOut: {show: true},
                        oneToOne: {show: true},
                        reset: {show: true},
                        rotateLeft: {show: true},
                        rotateRight: {show: true},
                        flipHorizontal: {show: true},
                        flipVertical: {show: true},
                        play: {show: false}
                    },
                    viewed() {
                    }
                };
                $timeout(() => {
                    if ($scope.doc1 && $scope.doc1.fileType === 'image') {
                        let img1Src = getElementById('image1');
                        img1Src.style.opacity = 0;
                        const viewer1 = new Viewer(img1Src, imageViewerParams);
                        $scope.viewers.push(viewer1);
                    }
                });
                $scope.goFullscreen = () => {
                    _goFullscreen('docViewer');
                };
                function getTargetItem($event) {
                    let elem = $event.currentTarget;
                    return $(elem).parent().parent().find('.item-field');
                }

                $scope.cancelBuyerEdit = () => {
                    $scope.editBuyer = false;
                    $scope.editedBuyer = {};
                    $timeout(() => {
                        $scope.$apply();
                    });
                };
                $scope.saveEditedBuyer = () => {
                    if ($scope.editBuyer) {
                        procedureService.updateItem($scope.procedure.ProcedureId, 'person', $scope.buyer._id, $scope.editedBuyer).then(() => {
                            toast.show('Les données ont été bien mises à jour.', 'success');
                            $route.reload();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                        });
                    }
                };
                $scope.cancelOwnerEdit = () => {
                    $scope.editOwner = false;
                    $scope.editedOwner = {};
                    $timeout(() => {
                        $scope.$apply();
                    });
                };
                $scope.saveEditedOwner = () => {
                    if ($scope.editOwner) {
                        procedureService.updateItem($scope.procedure.ProcedureId, 'person', $scope.owner._id, $scope.editedOwner).then(() => {
                            toast.show('Les données ont été bien mises à jour.', 'success');
                            $route.reload();
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                        });
                    }
                };
                // update procedure data
                $scope.edit = ($event, inputType = 'text', placeholder = '') => {
                    let _this = $event.currentTarget,
                            parent = $(_this).parent(),
                            item = $(getTargetItem($event));
                    let type = $(item).data('type'),
                            value = $(item).data('value'),
                            field = $(item).data('field'),
                            personSubType = $(item).data('sub-type');
                    if (field === 'personType') {
                        if (personSubType === 'seller') {
                            $scope.editSeller = true;
                            $scope.editedSeller = {
                                type: personSubType,
                                personType: value === PERSONNE_PHYSIQUE ? PERSONNE_MORALE : PERSONNE_PHYSIQUE,
                                isPro: value === PERSONNE_MORALE
                            };
                        } else if (personSubType === 'buyer') {
                            $scope.editBuyer = true;
                            $scope.editedBuyer = {
                                type: personSubType,
                                personType: value === PERSONNE_PHYSIQUE ? PERSONNE_MORALE : PERSONNE_PHYSIQUE,
                                isPro: value === PERSONNE_MORALE
                            };
                        } else if (personSubType === 'owner') {
                            $scope.editOwner = true;
                            $scope.editedOwner = {
                                type: personSubType,
                                personType: value === PERSONNE_PHYSIQUE ? PERSONNE_MORALE : PERSONNE_PHYSIQUE,
                                isPro: value === PERSONNE_MORALE
                            };
                        }
                        return;
                    }
                    if (value === true) {
                        value = 'OUI';
                    } else if (value === false) {
                        value = 'NON';
                    } else if (value === PERSONNE_PHYSIQUE) {
                        value = 'Particulier';
                    } else if (value === PERSONNE_MORALE) {
                        value = 'Société';
                    }
                    let inputFields = {
                        type: inputType,
                        field: type + '.' + field,
                        size: 14,
                        value: value,
                        placeholder: placeholder
                    };
                    if (field === 'saleDate,saleTime') {
                        let saleDate = $(item).data('data-saledate');
                        let saleTime = $(item).data('data-saletime');
                        value = moment(saleDate + ' ' + saleTime, 'DD/MM/YYYY HH:mm', true).format('YYYY-MM-DDTHH:mm');
                        let now = new Date(), minDate = new Date();
                        now.setMilliseconds(0);
                        now.setSeconds(0);
                        minDate.setYear(now.getFullYear() - 50);
                        inputFields.value = value;
                        inputFields.min = moment(minDate).format('YYYY-MM-DDTHH:mm');
                        inputFields.max = moment(now).format('YYYY-MM-DDTHH:mm');
                    }
                    $(item).html('');
                    let inputHTML = '<input ';
                    for (let f in inputFields) {
                        inputHTML += f + '="' + inputFields[f] + '" ';
                    }
                    inputHTML += '>';
                    $(item).append(inputHTML);
                    $(_this).addClass('hidden');
                    $(parent).find($('.btn-validate-edit')).removeClass('hidden');
                    $(parent).find($('.btn-cancel-edit')).removeClass('hidden');
                    if ($(item).hasClass('badge')) {
                        $(item).removeClass('badge');
                        $(item).addClass('badge-off');
                    }//
                };
                $scope.cancelEdit = ($event) => {
                    let _this = $event.currentTarget,
                            parent = $(_this).parent(),
                            item = $(getTargetItem($event));
                    let value = $(item).data('value');
                    $(item).html(value);
                    $(_this).addClass('hidden');
                    $(parent).find($('.btn-edit')).removeClass('hidden');
                    $(parent).find($('.btn-validate-edit')).addClass('hidden');
                    if ($(item).hasClass('badge-off')) {
                        $(item).removeClass('badge-off');
                        $(item).addClass('badge');
                    }
                };
                $scope.validateEdit = ($event, origItem) => {
                    let _this = $event.currentTarget,
                            parent = $(_this).parent(),
                            item = $(getTargetItem($event));
                    let type = $(item).data('type'),
                            value = $(item).data('value') + '',
                            field = $(item).data('field'),
                            itemId = $(item).data('data-id'),
                            itemType = $(item).data('data-type'),
                            itemPersonTitle = $(item).data('data-title'),
                            itemPersonType = $(item).data('data-person-type');
                    let newValue = ($(item).find('input').val() || '').toUpperCase(),
                            company = null, firstname = null, lastname = null;
                    if (newValue === 'PARTICULIER') {
                        newValue = PERSONNE_PHYSIQUE;
                    } else if (newValue === 'SOCIÉTÉ') {
                        newValue = PERSONNE_MORALE;
                        company = prompt('Veuillez taper le nom de la société', '');
                    } else if (newValue === 'OUI' || newValue === 'TRUE') {
                        newValue = true;
                    } else if (newValue === 'NON' || newValue === 'FALSE') {
                        newValue = false;
                    }
                    let data = type === 'person' ? {
                        type: itemType,
                        personType: itemPersonType
                    } : {};
                    if (company !== null && company.length > 1) {
                        data.company = company;
                    }
                    if (['certificat-immatriculation', 'declaration-cession',
                        'declaration-achat'].indexOf(type) > -1) {
                        if (field === 'saleDate,saleTime') {
                            let saleDateTime = moment(newValue);
                            data.saleDate = saleDateTime.format('DD/MM/YYYY');
                            data.saleTime = saleDateTime.format('HH:mm');
                        }
                    }
                    if (field === 'marriedname') {
                        data['title'] = itemPersonTitle;
                    }
                    if (newValue !== value.toUpperCase()) {
                        /*if (['TRUE', 'FALSE', 'OUI', 'NON'].indexOf(newValue) > -1) {
                         newValue = newValue === 'TRUE' || newValue === 'OUI';
                         }*/
                        if (field !== 'saleDate,saleTime') {
                            $(item).data('value', newValue);
                            data[field] = newValue;
                        }
                        procedureService.updateItem($scope.procedure.ProcedureId, type, itemId, data).then(() => {
                            toast.show('Les données ont été bien mises à jour.', 'success');
                            if (typeof newValue === 'boolean') {
                                $(item).html(newValue ? 'Oui' : 'Non');
                            } else {
                                if (newValue === PERSONNE_PHYSIQUE) {
                                    $(item).html('Particulier');
                                } else if (newValue === PERSONNE_MORALE) {
                                    $(item).html('Société');
                                } else {
                                    $(item).html(newValue);
                                }
                            }
                            $(item).data('value', newValue);
                            try {
                                origItem[field] = newValue;
                            } catch (ex) {
                                console.log(ex);
                            }
                            $(_this).addClass('hidden');
                            $(parent).find($('.btn-edit')).removeClass('hidden');
                            $(parent).find($('.btn-cancel-edit')).addClass('hidden');
                            if ($(item).hasClass('badge-off')) {
                                $(item).removeClass('badge-off');
                                $(item).addClass('badge');
                            }
                        }).catch((err) => {
                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                        });
                    }
                };
                // update procedure data
                $scope.closeDialog = (accept = null, close = false) => {
                    $location.search('doc', null);
                    if (close === true) {
                        $mdDialog.hide();
                        return;
                    }
                    if (typeof accept === 'boolean') {
                        if ($scope.doc1.needConcordanceVerif && $scope.doc2 === null) {
                            alert('Vous ne pouvez pas valider ce document car il ' +
                                    'faut vérifier sa concordance par rapport à ' +
                                    'un autre document qui n\'a pas encore été téléversé.');
                            $mdDialog.hide();
                            return;
                        }
                        if (accept === true) {
                            $mdDialog.hide();
                            documents.check($scope.document, true).then((updatedDocument) => {
                                toast.show('Le document "' + $scope.document.label + '" a bien été marqué comme ' +
                                        ($scope.accepted ? 'accepté' : 'refusé') + ' .', 'success', true, 500);
                                $scope.document.status = updatedDocument.status;
                                $location.search('tab', 2);
                                $route.reload();
                            }).catch((err) => {
                                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                            });
                        } else {
                            //_confirmDocDecision($scope.document, accept);

                            $mdDialog.show({
                                templateUrl: TEMPLATES_DIR + 'new.reject.document.html',
                                parent: angular.element(document.body),
                                fullscreen: true,
                                multiple: true,
                                controller: RejectDocumentCtrl,
                                controllerAs: 'ctrl',
                                locals: {
                                    document: $scope.document,
                                    closeParentDialog: $scope.closeDialog
                                }
                            }).then(() => {
                                /* dialog closed */
                            });
                            function RejectDocumentCtrl($mdDialog, document, closeParentDialog) {
                                var ctrl = this;
                                ctrl.document = document;
                                ctrl.comment = document.comment || '';
                                ctrl.motifs = [
                                    {label: 'Type incorrect', checked: false},
                                    {label: 'Document illisible', checked: false},
                                    {label: 'Document raturé', checked: false},
                                    {label: 'Signature absente', checked: false},
                                    {label: 'Signature non conforme', checked: false},
                                    {label: 'Document non daté', checked: false},
                                    {label: 'Document n\'est pas barré', checked: false},
                                    {label: 'Date invalide', checked: false},
                                    {label: 'Document non valable / invalide / expiré', checked: false},
                                    {label: 'Date et heure de cession sont incorrectes', checked: false},
                                    {label: 'Document surchargé', checked: false}
                                ];
                                ctrl.newMotifs = gData.rejectDocumentMotifs;

                                ctrl.addMotif = (motif) => {
                                    if (typeof ctrl.comment !== 'string') {
                                        ctrl.comment = '';
                                    }
                                    if (motif.checked) {
                                        ctrl.comment = ctrl.comment.replace(motif.label, '');
                                    } else {
                                        ctrl.comment += (motif.label || '') + ', ';
                                    }
                                    if (ctrl.comment.charAt(0) === ',' || ctrl.comment.charAt(0) === ' ') {
                                        ctrl.comment = ctrl.comment.substring(1);
                                    }
                                    ctrl.comment = ctrl.comment.replace(' , ,', ' ,');
                                    ctrl.comment = ctrl.comment.replace(', , ', ', ');
                                };
                                ctrl.closeDialog = function (confirm = false) {
                                    ctrl.document.comment = ctrl.comment;
                                    if (confirm === true) {
                                        documents.check(ctrl.document, false).then((updatedDocument) => {
                                            $mdDialog.hide();
                                            closeParentDialog(null, true);
                                            toast.show('Le document "' + ctrl.document.label + '" a bien été marqué comme ' +
                                                    (ctrl.accepted ? 'accepté' : 'refusé') + ' .', 'success', true);
                                            ctrl.document.status = updatedDocument.status;
                                            $location.search('tab', 2);
                                            $route.reload();
                                        }).catch((err) => {
                                            toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                                        });
                                    } else {
                                        $mdDialog.hide();
                                }
                                };
                            }

                            RejectDocumentCtrl.prototype.$onInit = function () {};
                            /*$mdDialog.show({
                             parent: angular.element(document.body),
                             templateUrl: TEMPLATES_DIR + 'confirm.document.html',
                             fullscreen: true,
                             multiple: true,
                             locals: {
                             accepted: accept,
                             document: $scope.document,
                             closeParentDialog: $scope.closeDialog
                             },
                             controller: ConfirmDocDecisionCtrl,
                             controllerAs: 'ctrl'
                             });
                             function ConfirmDocDecisionCtrl($location, $route, $mdDialog, toast, documents, accepted, document, closeParentDialog) {
                             var ctrl = this;
                             ctrl.accepted = accepted;
                             ctrl.document = document;
                             ctrl.closeDialog = function (confirm = false) {
                             if (confirm) {
                             documents.check(ctrl.document, ctrl.accepted).then((updatedDocument) => {
                             $mdDialog.hide();
                             closeParentDialog(null, true);
                             toast.show('Le document "' + ctrl.document.label + '" a bien été marqué comme ' +
                             (ctrl.accepted ? 'accepté' : 'refusé') + ' .', 'success', true);
                             ctrl.document.status = updatedDocument.status;
                             $location.search('tab', 2);
                             $route.reload();
                             }).catch((err) => {
                             toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                             });
                             } else {
                             $mdDialog.hide();
                             // nothing to do
                             }//
                             };
                             }*/
                        }
                    } else {
                        //$window.location.href = $location.path();
                    }//
                };
            }
        });
    }

    function openCompareDocumentsViewer($scope) {
        $mdDialog.show({
            parent: angular.element(document.body),
            templateUrl: TEMPLATES_DIR + 'compare.2.documents.viewer.html',
            locals: {
                documents: $scope.procedure.Documents
            },
            controller: ($scope, $mdDialog, documents) => {
                $scope.viewers = [];
                $scope.documents = documents;
                const imageViewerParams = {
                    inline: true,
                    backdrop: false,
                    button: false,
                    navbar: false,
                    title: false,
                    keyboard: false,
                    loading: true,
                    toolbar: {
                        zoomIn: {show: true},
                        zoomOut: {show: true},
                        oneToOne: {show: true},
                        reset: {show: true},
                        rotateLeft: {show: true},
                        rotateRight: {show: true},
                        flipHorizontal: {show: true},
                        flipVertical: {show: true},
                        play: {show: false}
                    },
                    viewed() {
                    }
                };
                $scope.selectDoc1 = () => {
                    $scope.doc_1 = JSON.parse($scope.doc_1);
                    if ($scope.doc_1.fileType === 'image') {
                        let img = _createImageElement('concordance_image_1', $scope.doc_1.url, 'concordance_viewer1');
                        $scope.viewers[0] = new Viewer(img, imageViewerParams);
                    } else {
                        _createObjectElement('concordance_pdf_1', $scope.doc_1.url, 'concordance_viewer1');
                    }
                };
                $scope.selectDoc2 = () => {
                    $scope.doc_2 = JSON.parse($scope.doc_2);
                    if ($scope.doc_2.fileType === 'image') {
                        let img = _createImageElement('concordance_image_2', $scope.doc_2.url, 'concordance_viewer2');
                        $scope.viewers[1] = new Viewer(img, imageViewerParams);
                    } else {
                        _createObjectElement('concordance_pdf_2', $scope.doc_2.url, 'concordance_viewer2');
                    }
                };
                $scope.closeDialog = () => {
                    $mdDialog.hide();
                };
            }
        });
    }

    function _createImageElement(id, src, parentId) {
        let parent = document.getElementById(parentId);
        parent.innerHTML = '';
        let img = document.createElement('img');
        img.src = src;
        img.id = id;
        img.classList.add('viewer');
        img.style.opacity = 0;
        parent.appendChild(img);
        return img;
    }

    function _createObjectElement(id, src, parentId) {
        let parent = document.getElementById(parentId);
        parent.innerHTML = '';
        let obj = document.createElement('object');
        obj.type = 'application/pdf';
        obj.data = src;
        obj.classList.add('viewer');
        parent.appendChild(obj);
    }

    function _getDocumentTypeLabel(document = {}) {
        const documentType = typeof document === 'string' ? document : document.type;
        for (let i in documentTypes) {
            if (i === documentType) {
                return documentTypes[i].label;
            }
        }//
    }
    function _getDocumentFileTypeIcon(document = {}) {
        const ext = (document.path || '').substr(-4, 4);
        switch (ext) {
            case '.pdf':
                return '../../../assets/img/pdf_icon.png';
            case '.png':
                return '../../../assets/img/png_icon.png';
            case '.jpg':
            case 'jpeg':
                return '../../../assets/img/jpg_icon.png';
            default:
                return '../../../assets/img/default_icon.png';
        }//
    }
    function _getDocumentFileType(document = {}) {
        const ext = (document.path || '').substr(-4, 4).toLowerCase().replace('.', '');
        if (['jpg', 'jpeg', 'png'].indexOf(ext) > -1) {
            return 'image';
        } else if (ext === 'pdf') {
            return 'pdf';
        } else {
            return null;
        }//
    }
    function _getVehicleColor(vehicle = {}) {
        switch (vehicle.color) {
            case 'noir':
                return {
                    name: 'Noir',
                    code: '#000'
                };
                break;
            case  'marron':
                return {
                    name: 'Marron',
                    code: '#582900'
                };
                break;
            case  'rouge':
                return {
                    name: 'Rouge',
                    code: '#F00'
                };
                break;
            case  'orange':
                return {
                    name: 'Orange',
                    code: '#ED7F10'
                };
                break;
            case  'jaune':
                return {
                    name: 'Jaune',
                    code: '#0FF'
                };
                break;
            case  'vert':
                return {
                    name: 'Vert',
                    code: '#0F0'
                };
                break;
            case  'bleu':
                return {
                    name: 'Bleu',
                    code: '#00F'
                };
                break;
            case  'beige':
                return {
                    name: 'Beige',
                    code: '#C8AD7F'
                };
                break;
            case  'gris':
                return {
                    name: 'Gris',
                    code: '#606060'
                };
                break;
            case  'blanc':
                return {
                    name: 'Blanc',
                    code: '#FFF'
                };
                break;
            case  'autre':
                return {
                    name: 'Autre',
                    code: '#'
                };
                break;
            default:
                return null;
        }//
    }
    function _confirmDocDecision(doc, accepted) {
        $mdDialog.show({
            parent: angular.element(document.body),
            templateUrl: TEMPLATES_DIR + 'confirm.document.html',
            fullscreen: true,
            multiple: true,
            locals: {
                accepted: accepted,
                document: doc
            },
            controller: ConfirmDocDecisionCtrl,
            controllerAs: 'ctrl'
                    /*controller: ($scope, $location, $route, $mdDialog, toast, documents, accepted, document) => {
                     $scope.accepted = accepted;
                     $scope.document = document;
                     $scope.closeDialog = (confirm = false) => {
                     $mdDialog.hide();
                     if (confirm) {
                     documents.check($scope.document, $scope.accepted).then((updatedDocument) => {
                     toast.show('Le document "' + $scope.document.label + '" a bien été marqué comme ' +
                     ($scope.accepted ? 'accepté' : 'refusé') + ' .', 'success', true);
                     $scope.document.status = updatedDocument.status;
                     $location.search('tab', 2);
                     $route.reload();
                     }).catch((err) => {
                     toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                     });
                     } else {
                     // nothing to do
                     }//
                     };
                     }*/
        });
        function ConfirmDocDecisionCtrl($location, $route, $mdDialog, toast, documents, accepted, document) {
            var ctrl = this;
            ctrl.accepted = accepted;
            ctrl.document = document;
            ctrl.closeDialog = (confirm = false) => {
                $mdDialog.hide();
                if (confirm) {
                    documents.check(ctrl.document, ctrl.accepted).then((updatedDocument) => {
                        toast.show('Le document "' + ctrl.document.label + '" a bien été marqué comme ' +
                                (ctrl.accepted ? 'accepté' : 'refusé') + ' .', 'success', true);
                        ctrl.document.status = updatedDocument.status;
                        $location.search('tab', 2);
                        $route.reload();
                    }).catch((err) => {
                        toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE);
                    });
                } else {
                    // nothing to do
                }//
            };
        }
    }
    function sortArrayOnKey(array, key) {
        array.sort((a, b) => {
            if (a.position > b.position)
                return 1;
            else if (a.position < b.position)
                return -1;
            else
                return 0;
        });
    }
    function mod97(string) {
        var checksum = string.slice(0, 2), fragment;
        for (var offset = 2; offset < string.length; offset += 7) {
            fragment = String(checksum) + string.substring(offset, offset + 7);
            checksum = parseInt(fragment, 10) % 97;
        }
        return checksum;
    }
    function _getDuplicataMotifLabel(motif) {
        switch (motif) {
            case 'p':
                return 'Perte';
            case 'v':
                return 'Vol';
            case 'd':
                return 'Détérioration';
        }
    }

    return {
        messages: {
            'no-document': 'Aucun document n\'est disponible pour le moment.',
            'no-info': 'Aucune information n\'est disponible pour le moment.'
        },
        getAllProcedureTypes: () => {
            return getAllProcedureTypes();
        },
        getProcedureName: (procedureType = null) => {
            return getProcedureName(procedureType);
        },
        getPersonTitle: (person = {}) => {
            return _getPersonTitle(person);
        },
        getPersonName: (person = {}) => {
            return _getPersonName(person);
        },
        initProcedurePeople: ($scope = {}) => {
            if ($scope.item) {
                let defaultPersonType = _getDefaultPersonType($scope.procedureType);
                if (typeof $scope.item.Owner !== 'undefined') {
                    let person = $scope.item.Owner;
                    person.titleText = _getPersonTitle(person);
                    person.isPro = _isCompany(person);
                    person.personTypeLabel = _getPersonTypeLabel(person);
                    person.hasAddress = _hasAddress(person);
                    person.name = _getPersonName(person);
                    person.hasBirthInfos = _hasBirthInfos(person);
                    person.age = _getPersonAge(person);
                    $scope.owner = person;
                    if (defaultPersonType === 'owner') {
                        $scope.defaultPerson = person;
                    }
                    $scope.newAddress = person.Address;
                    $scope.oldAddress = $scope.item.OwnerAddress;
                } else {
                    for (let i = 0; i < $scope.item.People.length; i++) {
                        let person = $scope.item.People[i];
                        person.titleText = _getPersonTitle(person);
                        person.isPro = _isCompany(person);
                        person.personTypeLabel = _getPersonTypeLabel(person);
                        person.hasAddress = _hasAddress(person);
                        person.name = _getPersonName(person);
                        person.hasBirthInfos = _hasBirthInfos(person);
                        switch (person.type) {
                            case 'buyer':
                                person.age = _getPersonAge(person);
                                $scope.buyer = person;
                                break;
                            case 'seller':
                                $scope.seller = person;
                                break;
                            case 'co-buyer':
                                $scope.coBuyers.push(person);
                                if (person.firstCoBuyer) {
                                    $scope.firstCoBuyer = person;
                                }
                                break;
                        }
                        if (defaultPersonType === person.type) {
                            $scope.defaultPerson = person;
                        }
                    }
                }
            }//
        },
        getVehicleInfos: (vehicleRegistrationNum) => {
            return new Promise((resolve, reject) => {
                if (vehicleRegistrationNum === '') {
                    reject('NoVehicleRegistrationNum');
                }
                procedureService.getVehicleInfos(vehicleRegistrationNum).then((data) => {
                    for (let i in data) {
                        let label = '';
                        switch (i) {
                            case 'vehicleType':
                                label = 'Genre national';
                                break;
                            case 'vehicleIdentificationNum':
                                label = 'Numéro d\'identification du véhicule (VIN)';
                                break;
                            case 'vehicleFirstRegistrationDate':
                                label = 'Date de mise en circulation';
                                break;
                            case 'vehicleRegistrationDate':
                                label = 'Date du certificat d\'immatriculation';
                                break;
                            case 'vehicleFuel':
                                label = 'Energie';
                                break;
                            case 'vehicleHorsepower':
                                label = 'Puissance';
                                break;
                            case 'vehicleTaxableHorsepower':
                                label = 'Puissance administrative';
                                break;
                            case 'vehicleCO2':
                                label = 'Emissions de CO2';
                                break;
                            case 'vehicleMake':
                                label = 'Marque';
                                break;
                            case 'vehicleModel':
                                label = 'Modèle';
                                break;
                            case 'vehicleColor':
                                label = 'Couleur';
                                break;
                        }
                        data[i] = {
                            value: data[i],
                            label: label
                        };
                    }
                    resolve(data);
                }).catch((err) => {
                    reject(err);
                });
            });
        },
        getMaterialName: (material) => {
            return 'aluminum' ? 'Aluminium' : 'Plexiglass';
        },
        getVehicleType: (type) => {
            switch (type) {
                case 'auto':
                    return 'Voiture, camion, ...';
                case 'moto':
                    return 'Moto, scooter, quad';
            }
        },
        getCustomizationName: (type, customText = '') => {
            switch (type) {
                case 'none':
                    return 'Aucune';
                case 'rcg-logo':
                    return 'Logo RCG';
                case 'text':
                    return 'Texte personnalisé : ' + customText;
            }//
        },
        initVehicle: ($scope = {}) => {
            if ($scope.item.Vehicle) {
                $scope.vehicle = $scope.item.Vehicle;
                if ($scope.vehicle.color) {
                    $scope.vehicle.colorInfo = _getVehicleColor($scope.vehicle);
                }
            }//
        },
        isDocOK: function (doc) {
            return [
                'document-accepted',
                'document-signed',
                'document-downloaded'
            ].indexOf(doc.status) > -1;
        },
        initDocuments: function ($scope = {}) {
            let documents;
            if ($scope.procedure) {
                documents = $scope.procedure.Documents || [];
                for (let i = 0; i < documents.length; i++) {
                    let document = documents[i];
                    document.typeLabel = _getDocumentTypeLabel(document);
                    document.isType = document.subType === null;
                    document.isOK = this.isDocOK(document);
                    document.icon = _getDocumentFileTypeIcon(document);
                    document.url = config.websiteURL/*'https://v1.api.reflexecg.eu' /**/ + '/' + document.path;

                    if ($scope.procedure.isCAD) {
                        document.concordance = _getCADConcordance(documents, document);
                    } else if ($scope.procedure.isCIWW) {
                        document.concordance = _getCIWWConcordance(documents, document);
                    } else {
                        document.concordance = _getCIMConcordance(documents, document);
                    }

                    document.questions = this.getDocValidationQuestions(document, $scope.procedure.type);
                    document.fileType = _getDocumentFileType(document);
                    document.isCIM = document.field === 'certificat-immatriculation';
                    document.isOldCIM = document.field === 'ancien-certificat-immatriculation';
                    document.isToSign = document.signatureURL !== null ||
                            document.status === 'document-awaiting-signature';
                    document.isReceipt = document.field === 'accuse-enregistrement';
                    document.isVerifDocument = !document.isReceipt &&
                            document.status === 'document-downloaded';
                    document.isToUpload = document.uploadedAt !== null ||
                            (document.status === 'document-awaiting-upload' &&
                                    !document.isOldCIM && !document.isReceipt) ||
                            (!document.isOldCIM && !document.isToSign && !document.isReceipt && !document.isVerifDocument);
                    document.isUploaded = document.status === 'document-uploaded';
                    document.isAccepted = document.status === 'document-accepted';
                    document.isRejected = document.status === 'document-rejected';
                    document.isChecked = document.isAccepted || document.isRejected;
                    if (document.isToUpload) {
                        document.isRecto = document.field.indexOf('recto') > -1;
                        document.isVerso = document.field.indexOf('verso') > -1;
                        document.isRectoVerso = document.isRecto === true || document.isVerso === true;
                    }

                    document.position = _getNewDocumentPosition(document, $scope.procedure.type);
                    if (document.isOldCIM) {
                        $scope.oldCIMDocument = document;
                        $scope.procedure.isTMSReady = document.status === 'document-accepted' &&
                                $scope.procedure.CertificatImmatriculation.status === 'tms-ready';
                    }

                    if (document.isReceipt && document.isOK) {
                        $scope.procedure.hasReceipt = true;
                    }

                    let docStatus = document.Status.name;
                    document.Status.name = docStatus[0].toUpperCase() + docStatus.slice(1);
                }

                documents.sort((a, b) => {
                    if (a.position > b.position) {
                        return 1;
                    } else if (a.position < b.position) {
                        return -1;
                    } else {
                        return 0;
                    }
                });
                $scope.procedure.documentsFields = [];
            }//
        },
        sortArrayWithKey: (array, key) => {

        },
        isHandled: (procedure = {}, byUser = null, canBeHandled = null) => {
            let isHandled = procedure.handledAt !== null && procedure.UserId !== null;
            if (canBeHandled !== null) {
                return !isHandled;
            }
            if (byUser !== null) {
                return isHandled && procedure.UserId === $rootScope.currentUser._id;
            }
            return isHandled;
        },
        openDocumentsViewer: (document, $scope) => {
            return openDocumentsViewer(document, $scope);
        },
        openCompareDocumentsViewer: ($scope) => {
            return openCompareDocumentsViewer($scope);
        },
        getOrigin: (procedure = {}) => {

            let origin = {
                name: procedure.origin || ''
            };
            switch (origin.name) {

                case 'app-mobile':
                    origin.icon = 'phone_android';
                    origin.label = 'Application mobile';
                    break;
                case 'bo':
                    origin.icon = 'desktop_mac';
                    origin.label = 'Back office';
                    break;
                case 'borne':
                    origin.icon = 'touch_app';
                    origin.label = procedure.Terminal ? procedure.Terminal.name : 'Borne inconnue';
                    break;
                case 'site-web':
                    origin.icon = 'laptop';
                    origin.label = procedure.WebApp ? procedure.WebApp.name : 'Web app';
                    break;
                case 'app-tablette-pro':
                    origin.icon = 'tablet_android';
                    origin.label = procedure.Tablet ? procedure.Tablet.name : 'Tablette inconnue';
                    break;
                default:
                    return null;
            }//
            return origin;
        },
        getStatus: (procedure = {}) => {
            let status = {
                status: procedure.status || ''
            };
            switch (status.status) {
                case 'new':
                    status.label = 'Crée';
                    break;
                case 'pending':
                    status.label = 'En cours';
                    break;
                case 'completed':
                    status.label = 'Terminé';
                    break;
                default:
                    return null;
            }
            return status;
        },
        getProcedurePaymentMotifLabel: (payment) => {
            if(!payment || !payment.motif){
                return null;
            }
            switch (payment.motif) {
                case 'paiement-initial':
                    return 'Paiement initial';
                case 'paiement-taxes':
                    return 'Paiement de taxes';
                case  'supplement-ants':
                    return 'Supplément démarche ANTS';
                case 'supplement-taxes':
                    return 'Supplément taxes';
                case 'autre':
                    return 'Autre';
                default:
                    return null;
            }
        },
        getProcedurePaymentSupportLabel: (payment) => {
            if (payment.PaymentRequest !== null) {
                return 'Compte prépayé';
            } else if (payment.ExternalPaymentId !== null) {
                return 'En ligne';
            } else if (payment.method === 'terminal') {
                return 'Borne';
            }
        },
        getProcedurePaymentMethodLabel: (payment) => {
            if (payment.PaymentRequest !== null) {
                switch (payment.PaymentRequest.method) {
                    case 'especes':
                        return 'Espèces';
                    case 'carte-bancaire':
                        return 'Carte bancaire';
                    case 'virement-bancaire':
                        return 'Virement bancaire';
                    case 'autre':
                        return '';

                }
            } else if (payment.ExternalPaymentId !== null) {
                return 'Carte bancaire';
            } else if (payment.method === 'terminal') {
                return 'Carte bancaire';
            }
        },
        getPaymentMethodText: (payment) => {
            switch (payment) {
                case 'terminal':
                case 'card':
                    return 'Carte bancaire';
                case 'bank-transfer':
                    return 'Virement bancaire';
                case 'box':
                    return 'Box RCG prépayé';
                case 'cash':
                    return '-';
            }
            return null;
        },
        getPaymentMethodDetails: (payment) => {
            let paymentMethod;
            switch (payment.method) {
                case 'card':
                    paymentMethod = payment.PaymentCard;
                    return paymentMethod ? paymentMethod.type + ' - ' + paymentMethod.number : '-';
                case 'bank-transfer':
                    paymentMethod = payment.Transfer;
                    let bankAccount = JSON.parse(paymentMethod.customerBankAccountDetails);
                    return bankAccount.bankName + '<br>' + 'XXXX XXXX XXXX XXXX XXXX XXXX X' +
                            bankAccount.accountNumberEnding;
                case 'box':
                    paymentMethod = payment.Box;
                    return 'Box RCG prépayé (N° <b>' + paymentMethod.serialNum + '</b> )';
                case 'terminal':
                    return 'TPE / TPA sur une borne';
                case 'cash':
                    return 'Espèces';
            }
            return '';
        },
        getPriceName: (price) => {
            return _getPriceName(price);
        },
        getDocValidationQuestions: (document, procedureType = null) => {
            return _getDocValidationQuestions(document, procedureType);
        },
        getDocumentTypes: (documentType = null) => {
            return _getDocumentTypes(documentType);
        },
        getProcedureOrigin: (procedure = null, withSite = false) => {
            if (procedure === null) {
                return null;
            }
            let name = 'Origine inconnue';
            switch (procedure.origin) {
                case 'app-tablette-pro':
                    if (procedure.Tablet) {
                        name = procedure.Tablet.name;
                        if (withSite) {
                            name += procedure.Tablet.site ? (' - ' + procedure.Tablet.site) : '';
                        }
                    }
                    break;
                case 'borne':
                    if (procedure.Terminal) {
                        name = procedure.Terminal.name;
                        if (withSite) {
                            name += procedure.Terminal.site ? (' - ' + procedure.Terminal.site) : '';
                        }
                    }
                    break;
                case 'app-mobile':
                    name = 'Application mobile';
                    break;
                case 'site-web':
                    if (procedure.WebApp) {
                        name = procedure.WebApp.name;
                    }
                    break;
            }
            return {
                name: name
            };
        },
        getRefundMethodLabel: (refundMethod) => {
            switch (refundMethod) {
                case 'especes':
                    return 'En espèces (via un compte prépayé)';
                case 'virement-bancaire':
                    return 'Par virement bancaire';
                case 'carte-bancaire':
                    return 'Sur la carte bancaire';
            }
            return null;
        },
        getRefundTypeLabel: (refundType) => {
            switch (refundType) {
                case 'total':
                    return 'Remboursement total : frais de service + éventuelles taxes';
                case 'taxes-uniquement':
                    return 'Remboursement de taxes uniquement';
                case 'partiel':
                    return 'Remboursement partiel (ex: taxes payées en trop)';
            }
            return null;
        },
        isValidIBAN: (input) => {
            var CODE_LENGTHS = {
                AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
                CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
                FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
                HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
                LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
                MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
                RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26
            };
            let iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, '');
            let code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
            let digits;
            if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
                return false;
            }
            digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
                return letter.charCodeAt(0) - 55;
            });
            return mod97(digits) === 1;
        },
        getDuplicataMotifLabel: (motif) => {
            return _getDuplicataMotifLabel(motif);
        },
        getDepartementByCodeDep(codeDep = '') {
            let dep = gData.departements.find(item => item.codeDep === codeDep + '');
            if (dep) {
                dep.link = config.depPostalCodesLink.replace('##commune##', dep.name.toLowerCase());
            }//
            return dep;
        },
        getDocumentTypeLabel(document) {
            return _getDocumentTypeLabel(document);
        },
        getDocumentFileTypeIcon(document) {
            return _getDocumentFileTypeIcon(document);
        },
        getDocumentFileType(document) {
            return _getDocumentFileType(document);
        },
        getSubProcedure(procedure) {
            return _getSubProcedure(procedure);
        }
    };
});